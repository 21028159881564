import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { connect } from 'react-redux';
import Seo from '../components/Seo'
import { setSearchIndexData, setSearchIndexDataLoaded } from '../state/actions'


const SearchTemplate = (props) => {
  const { data, indexLoaded, setSearchIndexData, setSearchIndexDataLoaded } = props;
  const {
    LunrIndex
  } = data

  useEffect(() => {
    if(indexLoaded === false) {
      console.log('searchtemplate useEffect indexLoaded: ', indexLoaded)
      setSearchIndexData(LunrIndex)
      setSearchIndexDataLoaded(true)
    }
  }, [indexLoaded]);


  return (
    <>
      <Seo slug="suchen" title="Suche - Moabiter Insel" description="Spannende Angebote in Moabit finden" keywords="" noFollow={false} noIndex={false} seoTitle="Suche - Moabiter Insel" url="https://www.moabiterinsel.de/suchen/" />
    </>
  )
}

export const query = graphql`
    query SearchQuery{
      LunrIndex
      allWpTaxonomy(filter: {name: {eq: "moabit_participant_category"}}) {
        nodes {
          name
          archivePath
        }
      }
      allWpParticipant(sort: {order: ASC, fields: mi_participant___miParticipantShorttitle}) {
        nodes {
          databaseId
          id
          slug
          title
          uri
          participantCategories {
            nodes {
              databaseId
              name
            }
          }
          mi_participant {
            miParticipantShorttitle
            miParticipantHeroSlider {
              imageFile {
                childImageSharp {
                  fluid(maxHeight: 540, maxWidth: 960, quality: 80, cropFocus: CENTER) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            miParticipantGeodata {
              latitude
              longitude
              streetName
              streetNumber
              placeId
              city
              postCode
              streetAddress
            }
          }
        }
      }
    }
`
const mapStateToProps = (state) => {
  return {
    indexLoaded: state.search.indexLoaded,
  }
}


const mapDispatchToProps = {
  setSearchIndexData, setSearchIndexDataLoaded
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchTemplate)

